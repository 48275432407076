import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { store } from "./app/store"
import "./index.css"
import "./index.css"
// import Nft from "./features/nft/Nft"
import {
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom"


// import Mobile from "./features/mobile/Mobile"

// import InDevelop from "./features/inDevelop/InDevelop"
import Layout from "./Layout"
import Page404 from "./helpers/Page404"
import { ChakraProvider } from "@chakra-ui/react"

// wagmi
import { createClient, WagmiConfig, configureChains, Chain } from "wagmi"
import { bsc, bscTestnet } from "@wagmi/core/chains"
import { publicProvider } from "wagmi/providers/public"
import Code from "./helpers/Code"

import Staking from "./features/games/Staking"
import Octa1000 from "./features/staking/octa/Octa1000"
import RedeV2 from "./features/staking/redev2/RedeV2"
import Octa200 from "./features/staking/octa/Octa200"
import Pac from "./features/staking/pac/Pac"
import Pacbsc from "./features/staking/pac/Pacbsc"
import Pacsolo from "./features/staking/pac/Pacsolo"


const octaSpace = {
  id: 800001,
  name: "Octa Space",
  network: "octa_space",
  nativeCurrency: {
    decimals: 18,
    name: "OCTA",
    symbol: "OCTA",
  },
  rpcUrls: {
    default: { http: ["https://rpc.octa.space"] },
    public: { http: ["https://rpc.octa.space"] },
  },
  blockExplorers: {
    etherscan: { name: "OctaScan", url: "https://explorer.octa.space" },
    default: { name: "OctaScan", url: "https://explorer.octa.space" },
  },
  testnet: false,
} as Chain

const redev2 = {
  id: 1972,
  name: "REDEV2",
  network: "redev2",
  nativeCurrency: {
    decimals: 18,
    name: "REDEV2",
    symbol: "REDEV2",
  },
  rpcUrls: {
    default: { http: ["https://rpc2.redecoin.eu/"] },
    public: { http: ["https://rpc2.redecoin.eu/"] },
  },
  blockExplorers: {
    etherscan: { name: "RedeScan", url: "https://explorer3.redecoin.eu" },
    default: { name: "RedeScan", url: "https://explorer3.redecoin.eu" },
  },
  testnet: false,
} as Chain

const { provider, webSocketProvider } = configureChains(
  [bsc, bscTestnet, octaSpace, redev2],
  [publicProvider()],
)

const client = createClient({
  provider,
  webSocketProvider,
  autoConnect: false,
})

// etc

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Staking />,
      },
      {
        path: "/octa1000",
        element: <Octa1000 />,
      },
      {
        path: "/redev2",
        element: <RedeV2 />,
      },
      {
        path: "/octa200",
        element: <Octa200 />,
      },
      {
        path: "/pac",
        element: <Pac />,
      },
      {
        path: "/pacbsc",
        element: <Pacbsc />,
      },
      {
        path: "/pacsolo",
        element: <Pacsolo />,
      },
    ],
  },
  {
    path: "*",
    element: <Page404 />,
  },
])


ReactDOM.createRoot(document.getElementById("root")!).render(
  <ChakraProvider resetCSS={true} disableGlobalStyle={true}>
    <Provider store={store}>
      <WagmiConfig client={client}>
        <RouterProvider router={router} />
      </WagmiConfig>
    </Provider>
  </ChakraProvider>,
)