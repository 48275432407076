import Logo from "../../images/logo-staking.svg"
import BurgerOpen from "../../images/icons/mob-burger-open.svg"
import BurgerClose from "../../images/icons/mob-burger-close.svg"

import tg_ru from '../../images/icons/tg_ru.svg'
import tg_en from '../../images/icons/tg_en.svg'
import x from '../../images/icons/x.svg'
import discord from '../../images/icons/discord.svg'

import { NavLink } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { useDisconnect } from "wagmi"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { setMobBurger } from "./Header.slice"



const Header = () => {
  // const mobBurgerOpen = false;
  const { disconnectAsync } = useDisconnect()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const mobBurger = useAppSelector(state => state.mobBurger.open)
  // const [mobBurger, setMobBurger] = useState<boolean>(false)


  const handleDisconnect = async () => {
    await disconnectAsync()
  }

  return (

    <header className="fixed w-full mt-5 z-40">
      <div className={`${mobBurger ? 'mob_element_close' : 'mob_element_open'} wrapper`}>

        <div id="header" className=" flex p-4 lg:gap-[180px] gap-0 lg:justify-normal justify-between  items-center bg-lightGray rounded-[20px] text-white">

          <div className="gap-5 flex flex-col w-full max-w-[15%] max-[1050px]:max-w-[fit-content] max-w-[920px]:hidden">
            <NavLink to="/" className="w-fit hover:text-textGray">
              <img src={Logo} alt="logotype" className="max-w-[120px] max-[600px]:max-w-[95px]" />
              {/* {import.meta.env.VITE_APP_DEVELOPMENT ?? ''} */}

            </NavLink>

          </div>

          <div className="flex flex-row gap-6 justify-center font-extrabold items-center text-sm font-orbitron max-[920px]:hidden">
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? "text-yellow" : "hover:text-textGray"
              }
            >
              Staking
            </NavLink>
            {/* <NavLink to='/nft' className={({ isActive }) => isActive ? 'w-fit decoration-dotted underline' : ''}>
                                NFT
                            </NavLink> */}
            {/* <NavLink to='https://bsc.pac-staking.com'
              target="_blank"
              className={({ isActive }) => isActive ? 'text-yellow' : 'hover:text-textGray'}>
              PAC BSC
            </NavLink> */}
            <NavLink
              to="https://pac10.pac-staking.com"
              target="_blank"
              className={({ isActive }) =>
                isActive ? "text-yellow" : "hover:text-textGray"
              }
            >
              PAC
            </NavLink>

            <NavLink
              to="https://solo.pac-staking.com"
              target="_blank"
              className={({ isActive }) =>
                isActive ? "text-yellow" : "hover:text-textGray"
              }
            >
              PAC SOLO
            </NavLink>

            <NavLink
              to="https://octa200.pac-staking.com"
              target="_blank"
              className={({ isActive }) =>
                isActive ? "text-yellow" : "hover:text-textGray"
              }
            >
              OCTA 200
            </NavLink>

            <NavLink
              to="https://octa1000.pac-staking.com"
              target="_blank"
              className={({ isActive }) =>
                isActive ? "text-yellow" : "hover:text-textGray"
              }
            >
              OCTA 1000
            </NavLink>

            <NavLink
              to="https://redev2.pac-staking.com"
              target="_blank"
              className={({ isActive }) =>
                isActive ? "text-yellow" : "hover:text-textGray"
              }
            >
              REDEV2
            </NavLink>
          </div>

          <div id="burger_open" className="min-[1200px]:hidden">
            <button
              onClick={() => {
                dispatch(setMobBurger())
              }}
              className="yellow_icon_btn"

            >
              <img src={BurgerOpen} alt="Open burger" />
            </button>
          </div>

        </div>

      </div>



      <div id="mob_menu" className={`${mobBurger ? 'mob_element_open' : 'mob_element_close'}`}>
        <div className="flex flex-col gap-[50px]">
          <div className="flex justify-between items-start">
            <NavLink to="/" className="w-fit">
              <img src={Logo} alt="logotype" className="max-w-[95px]" />
            </NavLink>
            <button
              onClick={() => {
                dispatch(setMobBurger())
              }}
              className="pl-4 mt-2"
            >
              <img src={BurgerClose} alt="Close burger" />
            </button>
          </div>
          <div className="flex flex-col gap-4">
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? "text-yellow" : "hover:text-textGray"
              }
            >
              Staking
            </NavLink>
            {/* <NavLink to='/nft' className={({ isActive }) => isActive ? 'w-fit decoration-dotted underline' : ''}>
                                NFT
                            </NavLink> */}
            {/* <NavLink to='https://bsc.pac-staking.com'
              target="_blank"
              className={({ isActive }) => isActive ? 'text-yellow' : 'hover:text-textGray'}>
              PAC BSC
            </NavLink> */}
            <NavLink
              to="https://pac10.pac-staking.com"
              target="_blank"
              className={({ isActive }) =>
                isActive ? "text-yellow" : "text-white"
              }
            >
              PAC
            </NavLink>

            <NavLink
              to="https://solo.pac-staking.com"
              target="_blank"
              className={({ isActive }) =>
                isActive ? "text-yellow" : "text-white"
              }
            >
              PAC SOLO
            </NavLink>

            <NavLink
              to="https://octa200.pac-staking.com"
              target="_blank"
              className={({ isActive }) =>
                isActive ? "text-yellow" : "text-white"
              }
            >
              OCTA 200
            </NavLink>

            <NavLink
              to="https://octa1000.pac-staking.com"
              target="_blank"
              className={({ isActive }) =>
                isActive ? "text-yellow" : "text-white"
              }
            >
              OCTA 1000
            </NavLink>

            <NavLink
              to="https://redev2.pac-staking.com"
              target="_blank"
              className={({ isActive }) =>
                isActive ? "text-yellow" : "text-white"
              }
            >
              REDEV2
            </NavLink>

          </div>
        </div>

        <div className="flex flex-row justify-start gap-3">

          <a className="yellow_icon_btn" href="">
            <img src={tg_ru} alt="telegram ru" />
          </a>
          <a className="yellow_icon_btn" href="">
            <img src={tg_en} alt="telegram en" />
          </a>
          <a className="yellow_icon_btn" href="">
            <img src={discord} alt="discord" />
          </a>
          <a className="yellow_icon_btn" href="">
            <img src={x} alt="social X" />
          </a>

        </div>

      </div>

    </header>

  )
}

export default Header