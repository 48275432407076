
import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import websocketReducer from './websocket/websocketSlice';
import { socketMiddleware } from './websocket/websocketMiddleware';
import { Socket } from './websocket/Socket';
import { mobBurger } from '../features/header/Header.slice';

const websocketMiddleware = socketMiddleware(new Socket());

export const store = configureStore({
  reducer: {
    websocket: websocketReducer,
    mobBurger: mobBurger.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(

    ),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
