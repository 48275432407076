import { NavLink, useNavigate } from "react-router-dom"



const Staking = () => {


  // const { data, isLoading, isError, error, refetch, isSuccess } = useGetAllGamesQuery()
  // const { data, isLoading, isError, error, refetch, isSuccess } = useGetAllGamesQuery()
  const navigate = useNavigate()

  const data = [
    // {
    //   name: 'PAC BSC',
    //   description: 'Buy PAC tickets, invest in the common fund, and trade with other participants. Get income from your investments!',
    //   price: '5000 coin',
    //   url: 'https://bsc.pac-staking.com'
    // },
    {
      name: 'PAC',
      description: 'Buy tickets and invest PAC in the common fund. Sell and buy tickets from other participants.',
      price: '10000 coin',
      url: 'https://pac10.pac-staking.com'
    },
    {
      name: 'PAC SOLO',
      description: 'Connect and disconnect nodes from server pool. Get income from your investments!',
      price: '50000 coin',
      url: 'https://solo.pac-staking.com'
    },
    {
      name: 'OCTA',
      description: 'Buy tickets and invest Octa.Space in the common fund. Sell and buy tickets from other participants.',
      price: '200 coin',
      url: 'https://octa200.pac-staking.com'
    },
    {
      name: 'OCTA',
      description: 'Buy tickets and invest Octa.Space in the common fund. Sell and buy tickets from other participants.',
      price: '1000 coin',
      url: 'https://octa1000.pac-staking.com'
    },
    {
      name: 'REDEV2',
      description: 'Buy tickets and invest REDEV2 in the common fund. Sell and buy tickets from other participants.',
      price: '1000 coin',
      url: 'https://redev2.pac-staking.com'
    }

  ]



  // if (isLoading) {
  //   return <Loader />
  // }
  return (
    <div className="background-image-black">
      <div className="wrapper-content">
        <h1 className="font-orbitron w-fit text-yellow lg:text-8xl md:text-6xl text-4xl font-extrabold mb-14">Staking</h1>

        <div className="grid grid-cols-3 gap-7 mt-10 max-[1300px]:grid-cols-2 max-[760px]:grid-cols-1">
          {data?.map((item, index: number) => {
            return (
              <div key={index} className="bg-yellow bg-gameShards max-[550px]:bg-[length:70%] bg-[length:50%] bg-no-repeat bg-right-top rounded-[20px] h-full">

                <div className="h-full overflow-hidden flex flex-col gap-4">
                  <div className="flex game-name text-white font-bold font-orbitron py-3 px-6 text-2xl max-w-[50%] w-[100%] bg-customBlack rounded-br-3xl max-[920px]:text-[18px]">
                    {item.name}
                  </div>

                  <div className="h-full overflow-hidden flex flex-col justify-between ">

                    <div className="font-semibold text-customBlack text-xl py-6 px-6 pb-0 font-medium max-[920px]:text-sm max-[920px]:font-bold max-[500px]:text-sm max-[500px]:font-semibold">
                      {item.description}
                    </div>

                    <div className="flex flex-row justify-between px-6 py-6">
                      <div className="text-2xl font-bold font-orbitron">Price</div>

                      <div className="text-2xl font-bold font-orbitron">
                        {item.price}
                      </div>
                    </div>


                    <div className="py-6 px-6 pt-0">
                      <button className="black_btn">

                        <NavLink
                          to={item.url}
                          target="_blank"
                          className='flex w-full text-center items-center justify-center'
                        // disabled={!item.active}

                        >
                          Start Staking
                        </NavLink>

                      </button>
                    </div>

                  </div>

                </div>

              </div>
            )
          })}
        </div>
      </div>

    </div>
  )
}

export default Staking
