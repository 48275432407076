"use client"
import { useNavigate, useParams } from "react-router-dom"
// import { useGetTournamentsMutation, useGetUserGameHistoryMutation, useGetUserGamesCountMutation } from "./Game.slice"
import { useState } from "react"
import 'swiper/css';
// import { IHistory, ITournamentsActiveAndHistory } from "../../"





const Octa200 = () => {


    let params = useParams()
    // const { data, isLoading: GameDataLoading, isSuccess: GameDataSuccess } = useGetGameByIdQuery(params.gameId)
    //   const [getTournaments, { isLoading: TournamentsDataLoading, isSuccess: TournamentsDataSuccess }] = useGetTournamentsMutation()
    //   const [getUserGamesCount, { isLoading: getUserGameCountLoading, isSuccess: getUserGameCountSuccess }] = useGetUserGamesCountMutation()

    //   const [getUserHistory, { isLoading: HistoryLoading, reset }] = useGetUserGameHistoryMutation()
    //   const navigate = useNavigate()

    //   const [gameHistory, setGameHistory] = useState<IHistory[]>()
    //   const [historyError, setHistoryError] = useState<boolean>(false)
    //   const limit = 5
    //   const [tournaments, setTournaments] = useState<ITournamentsActiveAndHistory>()
    //   const [pagesCount, setPagesCount] = useState<number>()

    // useEffect(() => {
    //   if (data)
    //     getUserGamesCount({
    //       id: tokenService.getUser().id,
    //       game: data?.code,
    //     }).unwrap()
    //       .then((response) => {
    //         console.log(response);
    //         setPagesCount(response)
    //       })
    //       .catch(err => {
    //         setHistoryError(true)
    //       })

    //   if (GameDataSuccess)
    //     getTournaments(data?.code)
    //       .unwrap()
    //       .then((responce: ITournamentsActiveAndHistory) => {
    //         console.log(responce);
    //         setTournaments(responce)
    //       })
    //       .catch(err => {
    //         console.log(err);
    //       })
    // }, [GameDataSuccess])


    // useEffect(() => {
    //   reset()
    //   console.log(pagination);

    //   getUserHistory({
    //     id: tokenService.getUser().id,
    //     game: data?.code,
    //     offset: pagination * limit,
    //     limit: limit
    //   })
    //     .unwrap()
    //     .then((response) => {
    //       console.log(response);
    //       if (response.length == 0) {
    //         console.log('errr');
    //         setHistoryError(true)
    //       }
    //       else {
    //         setGameHistory(response)
    //       }
    //     })
    //     .catch(err => {
    //       console.log(err);

    //       setHistoryError(true)
    //     })
    // }, [pagination])

    // useEffect(() => {
    //   if (GameDataSuccess)
    //     getUserHistory({
    //       id: tokenService.getUser().id,
    //       game: data?.code,
    //       offset: pagination * limit,
    //       limit: limit
    //     }).unwrap()
    //       .then((response) => {
    //         console.log(response);
    //         if (response.length == 0) {
    //           console.log('errr');
    //           setHistoryError(true)
    //         }
    //         else {
    //           setGameHistory(response)
    //         }
    //       })
    //       .catch(err => {
    //         setHistoryError(true)
    //       })

    // }, [GameDataSuccess])


    // if (GameDataLoading) {
    //   return <Loader />
    // }

    return (
        <div className="background-image-yellow">
        <div className="wrapper-content ">
          <div className="flex flex-col lg:md:gap-12 gap-8 justify-between ">
            <div className="flex flex-col lg:md:gap-10 gap-4">
              <h1 className="font-orbitron lg:w-2/3 text-yellow lg:text-8xl md:text-6xl text-4xl font-extrabold">OCTA Staking 200</h1>
              <div className="lg:md:w-1/2 w-full sm:w-4/5 font-medium lg:md:text-3xl text-base text-white">Buy tickets and invest $OCTA
                in general pool and get profit from invested funds. 1 ticket = 1000 $OCTA coins. Sell and buy tickets from other participants</div>
              <div className="text-yellow font-orbitron font-bold lg:text-2xl text-sm lg:w-2/5 w-full">How to calculate the reward?
                For the convenience of entering variables:</div>
              <div className="flex sm:flex-row gap-8 md:flex-row flex-col lg:gap-48">
                <div className="lg:text-2xl text-base text-white font-medium flex flex-col gap-0">
                  <span>W = your rewards</span>
                  <span>T = the number of your tickets</span>
                  <span>R = rewards per node</span>
                  <span>K = pool commission = 20%</span>
                  <span>W = T * R / 100 - K</span>
                </div>
                <div className="lg:text-2xl text-base text-white font-medium flex flex-col lg:mt-[62px]">
                  <span>W = your rewards</span>
                  <span>T = the number of your tickets</span>
                  <span>R = rewards per node</span>
                </div>
              </div>
            </div>
                    {/* <div className="lg:md:mt-12">
            <h2 className="font-orbitron w-fit text-yellow lg:text-8xl md:text-6xl text-4xl mt-2 font-extrabold mb-8">Tournaments</h2>
            {TournamentsDataSuccess ? (
              <div className="text-xl font-semibold grid lg:grid-cols-3 gap-4 md:grid-cols-2 grid-cols-1 mt-10">
                {tournaments?.active.map((item, index: number) => {
                  return (
                    <div key={index} className="bg-lightGray p-6 rounded-[20px] flex flex-row gap-2 text-white w-full">
                      <div className="flex flex-col w-full gap-6 justify-between">
                        <div className="flex flex-col gap-1">
                          <div className="flex flex-row justify-between items-base  gap-2" >
                            <div className="text-white font-orbitron text-3xl flex flex-col items-start w-2/3 ">
                              <div className="text-2xl font-bold max-[530px]:text-lg">{item.name}</div>
                            </div>
                            <span className="p-2 text-base font-bold flex flex-col juistify-center text-center items-center rounded-3xl text-white w-36 h-10 bg-[#007E3D] max-[530px]:text-[12px]">
                              active
                            </span>
                          </div>
                          <span className="pt-4 font-medium text-white text-2xl break-normal max-[530px]:text-sm">
                            {item.goal}
                          </span>

                        </div>
                        <div>
                          <ul>
                            <li className="flex flex-row justify-between">
                              <span className=" font-bold font-orbitron text-2xl max-[530px]:text-base">
                                Game
                              </span>
                              <span className="font-orbitron font-bold max-[530px]:text-base">
                                {data?.name}
                              </span>
                            </li>
                            <li className="flex flex-row justify-between">
                              <span className=" font-bold font-orbitron text-2xl max-[530px]:text-base">
                                Duration
                              </span>
                              <span className="font-orbitron font-bold max-[530px]:text-base">
                                7 days
                              </span>
                            </li>
                            <li className="flex flex-row justify-between">
                              <span className=" font-bold font-orbitron text-2xl max-[530px]:text-base">
                                Cost
                              </span>
                              <span className="font-orbitron font-bold max-[530px]:text-base">
                                {item.cost}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <button onClick={() => {
                          navigate(`/tournaments/${item.id}`);
                        }} className={`font-orbitron w-full text-yellow rounded-3xl bg-[#0D0D0D] text-xl font-bold p-3 text-center cursor-pointer disabled:opacity-30 max-[530px]:text-sm`}>
                          More detailed
                        </button>
                      </div>
                    </div>
                  )
                })}
                {tournaments?.history.map((item, index: number) => {
                  return (
                    <div key={index} className="bg-lightGray p-6 rounded-[20px] flex flex-row gap-2 text-white">
                      <div className="flex flex-col w-full gap-8 justify-between">
                        <div className="flex flex-col gap-2">
                          <div className="flex flex-row justify-between items-base gap-2 " >
                            <span className="font-orbitron text-2xl font-bold w-2/3 max-[530px]:text-lg">
                              {item.name} | {item.id}
                            </span>
                            <span className="p-2 text-base font-bold flex flex-col justify-center text-center items-center rounded-3xl text-white w-1/3 h-10 bg-[#898989] max-[530px]:text-[12px]">
                              Ended {item.createdAt.substring(5, 10).replace('-', '.')}
                            </span>
                          </div>
                        </div>
                        <div className="flex flex-row justify-between items-center text-2xl font-orbitron">
                          <span className="max-[530px]:text-base">
                            Game
                          </span>
                          <span className="max-[530px]:text-base">
                            {data?.name}
                          </span>
                        </div>
                        <HistotyTournamentRating tournament_id={item.id} typeTR="history" />
                        <button onClick={() => {
                          navigate(`/tournaments/history/${item.id}`);
                        }} className={`font-orbitron w-full text-yellow rounded-3xl bg-[#0D0D0D] text-xl font-bold p-3 text-center cursor-pointer disabled:opacity-30 max-[530px]:text-sm`}>
                          More detailed
                        </button>
                      </div>
                    </div>
                  )
                })}
              </div>)
              : (
                <div className="bg-lightGray rounded-[30px] flex flex-col items-center lg:md:mt-10 mt-3 gap-10 px-6 pt-16 pb-12 max-[920px]:pt-8 max-[920px]:pb-6">
                  <div className="flex flex-col items-center gap-5">
                    <div className="font-orbitron text-white text-center text-[28px] leading-[35px] max-[920px]:text-[18px] max-[920px]:leading-[23px]">
                      There have been no tournaments for this game yet, but they will appear soon.
                    </div>
                    <div className="font-chakra text-textGray text-[26px] leading-[34px] text-center max-[920px]:text-[16px] max-[920px]:leading-[21px]">
                      You can participate in other tournaments
                    </div>
                  </div>
                  <NavLink className="black_btn max-w-[475px]" to="/tournaments">
                    All tournaments
                  </NavLink>
                </div>)
            }
          </div> */}
                     <div className="lg:mt-44 mt-8">
            <div className=" flex lg:flex-row flex-col lg:gap-36 gap-10">
              <div className="flex flex-col lg:gap-8 gap-4">
                <div className="flex flex-row lg:text-5xl text-2xl text-white font-orbitron font-bold">Total Tickets</div>
                <div className="flex flex-row lg:text-8xl text-4xl text-yellow font-orbitron font-extrabold">3500 tickets</div>
              </div>
              <div className="flex flex-col lg:gap-8 gap-4">
                <div className="flex flex-row lg:text-5xl text-2xl text-white font-orbitron font-bold">Available tickets</div>
                <div className="flex flex-row lg:text-8xl text-4xl text-yellow font-orbitron font-extrabold">0 tickets</div>
              </div>
            </div>
            <div className="flex lg:flex-row flex-col  justify-between mt-20">
              <div className="lg:text-8xl text-4xl text-yellow font-extrabold font-orbitron">Pool</div>
              <div className="flex flex-col gap-2 lg:text-left text-right">
                <div className="lg:text-2xl text-xl text-white font-orbitron font-extrabold">Your tickets at all</div>
                <div className="lg:text-3xl text-2xl text-yellow font-orbitron font-extrabold">5 tickets</div>
              </div>
            </div>
            <div className="mt-14 gap-8 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3  ">
              {
                new Array(9).fill({
                  name: 'Pool',
                  user_tickets: 12,
                  available_tickets: 2,
                  s_requests:1,
                  p_requests:10
                }).map((item, index) => {
                  return (
                    <div className="w-full bg-lightGray h-full min-h-min rounded-3xl">
                      <div className="p-6 gap-14">
                        <div className="flex flex-col gap-8">
                          <div className="text-3xl text-yellow font-orbitron font-bold">{item.name} #{index + 1}</div>
                          <div className="flex flex-col">
                            <div className="flex flex-row justify-between text-white text-2xl font-orbitron font-bold">
                              <ul className="flex flex-col gap-3">
                                <li>Your tickets</li>
                                <li>Available tickets</li>
                                <li>Sales requests</li>
                                <li>Purchase requests</li>
                              </ul>
                              <ul className="flex flex-col gap-3 text-right">
                                <li>{item.user_tickets}</li>
                                <li>{item.available_tickets}</li>
                                <li>{item.s_requests}</li>
                                <li>{item.p_requests}</li>
                              </ul>
                            </div>
                          </div>
                          <button className="text-lg text-yellow font-orbitron font-semibold bg-black rounded-3xl px-6 py-3">Perform an operation</button>
                        </div>

                      </div>
                    </div>
                  )
                })
              }


            </div>

          </div>
        </div>
      </div >

    </div>

  )
    


}

export default Octa200

